//Importacion de tipografias 

@font-face {
    font-family: 'LeagueSpartan-Bold';
    src: url('../../fonts/LeagueSpartan-Bold.otf');
}

@font-face {
    font-family: 'Raphtalia';
    src: url('../../fonts/Raphtalia.ttf');
}

//Colors

$color-blue: #e94a60;
$color-blue-opaque: #e94a60;
$color-sky-blue: #3CBEF4;
$color-cyan: #009ee2;
$color-purple: #8F00FF;
$color-black: #0F2428;
$color-black-soft: #253238;
$color-green: #10d45f;
$color-purple-dark: #6d22cc;
$color-red: #ff6c4f;    //orange
$color-yellow: #fff760;
$color-yellow-light-dark:#FBD727;
$color-white: #ffffff;
$color-gray: #8e8e8e;
$color-gray-soft: #cfcfcf;
$color-gray-light: #646464;
$color-gray-very-light: #f0f0f0;
$color-gray-ultra-light: #F8F8F8;
$color-gray-dark: #494949;

//gradient colors

$color-gradient-primary: linear-gradient( to top, #0129B4 0%, #005AE6 100% );
$color-gradient-secondary: linear-gradient( to top, #3298c4 0%, #3CBEF4 100% );
$color-gradient-success: linear-gradient( to top, #0db450 0%, #12e466 100% );
$color-gradient-danger: linear-gradient( to top, #c0022e 0%, #ee053c 100% );
$color-gradient-warning: linear-gradient( to top, #eccb22 0%, #fddf45 100% );
$color-gradient-info: linear-gradient( to top, #0285bd 0%, #18baff 100% );
$color-gradient-light: linear-gradient( to top, #d8d8d8 0%, #f8f7f7 100% );
$color-gradient-dark: linear-gradient( to top, #0a171a 0%, #484f50 100% );

//Colors pallete

$color-primary: $color-red;
$color-secondary: $color-sky-blue;
$color-tertiary: $color-purple;
$color-quaternary: $color-black;
$color-quaternary: $color-black;
$color-quintenary: $color-green;

//fonts families

$font-family-primary: 'LeagueSpartan-Bold', sans-serif;
$font-family-secondary: 'Raphtalia', sans-serif;

//font sizes

$font-small: 0.8em;
$font-regular: 0.9em;
$font-normal: 1em;
$font-medium: 1.2em;
$font-large: 1.4em;
$font-extra-large: 1.6em;
$font-extra-large-plus: 1.8em;
$font-ultra-large: 2em;