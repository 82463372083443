@import './variables.scss';

/***************/
/*   BUTTONS   */ 
/***************/

.btn-model{
    border-radius: 0.65rem; 
    border: none;
    cursor: pointer;
    font-size: $font-medium;
    line-height: 1.5;
    outline: none;
    padding: 0.415rem 1.05rem;
    transition: all 100ms ease;
    &:hover {
        opacity: 0.85;
        transform: scale(1.05);
    }
}

@mixin general-btn ( $color, $name, $color-text: $color-white ){
    .mpi-btn-#{ $name } {
        @extend .btn-model;
        background-color: $color;
        color: $color-text;
    }
}

@include general-btn ( $color-primary, primary );
@include general-btn ( $color-secondary, secondary );
@include general-btn ( $color-green, success );
@include general-btn ( $color-red, danger );
@include general-btn ( $color-yellow-light-dark, warning, $color-black );
@include general-btn ( $color-cyan, info );
@include general-btn ( $color-gray-very-light, light, $color-black );
@include general-btn ( $color-black, dark );

.mpi-btn-link{
    @extend .btn-model;
    background-color: transparent;
    color: $color-primary;
    transition: none;
    &:hover{
        opacity: 1;
        text-decoration: underline;
    }
}

/***********************/
/*   OUTLINE BUTTONS   */ 
/***********************/

@mixin outline-btn( $color, $name, $color-text: $color-white ) {
    .mpi-btn-outline-#{ $name } {
        @extend .btn-model;
        background-color: transparent;
        border: 1px solid $color;
        color: $color;
        transition: 250ms all ease;
        &:hover{
            background-color: $color;
            color: $color-text;
        }
    }
}

@include outline-btn ( $color-primary, primary );
@include outline-btn ( $color-secondary, secondary );
@include outline-btn ( $color-green, success );
@include outline-btn ( $color-red, danger );
@include outline-btn ( $color-yellow-light-dark, warning, $color-black );
@include outline-btn ( $color-cyan, info );
@include outline-btn ( $color-gray-very-light, light, $color-black );
@include outline-btn ( $color-black, dark );

/************************/
/*   GRADIENT BUTTONS   */ 
/************************/
@mixin gradient-btn( $color, $name, $color-text: $color-white ) {
    .mpi-btn-gradient-#{ $name } {
        @extend .btn-model;
        background-image: linear-gradient(to bottom, #ff6f3a 0%, #ff5a2e 33%, #ff391a 66%, #ff1a08 100%);
        //background-image: $color;
        color: $color-text;
    }
}

@include gradient-btn ( $color-gradient-primary, primary );
@include gradient-btn ( $color-gradient-secondary, secondary );
@include gradient-btn ( $color-gradient-success, success );
@include gradient-btn ( $color-gradient-danger, danger );
@include gradient-btn ( $color-gradient-warning, warning, $color-black );
@include gradient-btn ( $color-gradient-info, info );
@include gradient-btn ( $color-gradient-light, light, $color-black );
@include gradient-btn ( $color-gradient-dark, dark );

/**************************/
/*   BACKGROUNDS COLORS   */ 
/**************************/

@mixin bg-colors( $color, $name ) {
    .mpi-bg-#{ $name } {
        background-color: $color;
    }
}

@include bg-colors ( $color-primary, blue );
@include bg-colors ( $color-secondary, cyan );
@include bg-colors ( $color-green, green );
@include bg-colors ( $color-red, red );
@include bg-colors ( $color-yellow-light-dark, yellow );
@include bg-colors ( $color-purple-dark, purple );
@include bg-colors ( $color-gray-very-light, gray );
@include bg-colors ( $color-black, black );
@include bg-colors ( $color-white, white );

/**************/
/*   BADGES   */ 
/**************/

@mixin badge ( $color, $name, $color-text: $color-white ) {
    .mpi-badge-#{ $name } {
        background-color: $color;
        border-radius: 15px;
        color: $color-text;
        font-size: 0.72em;
        font-weight: bold;
        outline: none;
        padding: 6px 12.5px;
        text-transform: uppercase;
    }
}

@include badge ( $color-primary, primary );
@include badge ( $color-secondary, secondary );
@include badge ( $color-green, success );
@include badge ( $color-red, danger );
@include badge ( $color-yellow-light-dark, warning, $color-black );
@include badge ( $color-cyan, info );
@include badge ( $color-gray-very-light, light, $color-black );
@include badge ( $color-black, dark );






























//Normal button
.btn-common{
    background-color: $color-primary;
    border: none;
    color: $color-white;
    cursor: pointer;
    font-size: $font-normal;
    outline: none;
    &:hover{
        transform: scale(1.05);
    }
}

.mappi-btn {
    @extend .btn-common;
    //  background-image: linear-gradient( to top, #dd5a6c 0%, #e93850 100% );
    //background-image: linear-gradient( to right, #Ffe861 0%, #FFA55E 33%, #FF594A 66%, #C51009 100% );
    background-image: linear-gradient(to bottom, #ff6f3a 0%, #ff5a2e 33%, #ff391a 66%, #ff1a08 100%);
    border-radius: 15px;
    padding: 10px 20px;
    text-align: center;
    transition: 300ms all ease;

    &:hover {
        opacity: 0.85;
    }
}


//outline button
.mappi-btn-outline {
    @extend .btn-common;
    border-radius: 15px;
    border: 1px solid $color-white;
    background-color: #ff5027;
    padding: 10px 20px;
    transition: 300ms all ease;
}

//Link button
.mappi-btn-link{
    @extend .btn-common;
    background-color: transparent;
    &:hover{
        text-decoration: underline;
    }
}