@import './assets/sass/theme/theme.scss';

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f7f7f7;
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.Mui-disabled {
  cursor: not-allowed !important;
}

.app-content {
  background-color: #fff;
  // min-height: 700px;
  // overflow-y: auto;
}

@media( min-width: 2100px ) {
  .app-container{
    width: 100%;
    .app-content{
      max-width: 2100px;
      margin: 0 auto;
      height: 100vh;
    }
  }
}

/* Estilos para la barra de desplazamiento */
::-webkit-scrollbar {
    width: 10px; /* Ancho de la barra de desplazamiento */
    z-index: 999; /* Establece el z-index */
}

/* Estilos para el riel de la barra de desplazamiento */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color del riel */
}

/* Estilos para el agarre o manija de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    background: #888; /* Color del agarre */
}

/* Estilos al pasar el rat�n por encima de la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color al pasar el rat�n por encima */
}

scrollbar {
    width: 10px; /* Ancho de la barra de desplazamiento */
}

/* Estilos para el riel de la barra de desplazamiento */
scrollbar-track {
    background: #f1f1f1; /* Color del riel */
}

/* Estilos para el agarre o manija de la barra de desplazamiento */
scrollbar-thumb {
    background: #888; /* Color del agarre */
}

/* Estilos al pasar el rat�n por encima de la barra de desplazamiento */
scrollbar-thumb:hover {
    background: #555; /* Color al pasar el rat�n por encima */
}