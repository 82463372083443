@import '../theme/variables.scss';

.c-title{
    border-radius: 40px;
    h1{
        color: $color-white;
        font-size: 2em;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding: 1rem;
        text-align: center;
    }
}

.component-subtitle{
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;
}

.items-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    .item-content{
        margin-bottom: 20px;
        margin-right: 30px;
        text-align: center;
        p{
            margin-bottom: 5px;
            margin-top: 5px;
            white-space: nowrap;
            cursor: pointer;
        }
        .square{
            display: flex;
            justify-content: center;
            span {
                border-radius: 10px;
                box-shadow: 0 0 10px #ccc;
                display: block;
                height: 60px;
                width: 60px;
            }
        }
    }
}